<template>
    <main class="main-section">
        <section id="top-section">
            <HeaderComponent></HeaderComponent>

            <div style="position: absolute; top: 0; left: 0; width: 100%; height: 100vh;">
                <div id="particles-js" style="position: absolute; width: 100%; height: 100%"></div>
            </div>

            <div class="cursor"></div>
        </section>

        <ProjectsComponent></ProjectsComponent>

        <ServicesComponent></ServicesComponent>
        
        <ContactusComponent></ContactusComponent>
    </main>
</template>

<style>
    .section-name {
        font-weight: 800 !important;
        font-size: 42px;
    }
    .dark .section-name{
        color: white;
    }
    .light .section-name{
        color: var(--light-text-color);
    }
    .dark, .light{
        transition: .4s;
    }

     /* Cursor Style */
    .cursor{
        z-index: 999;
        background: var(--secondary-background-color);
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        pointer-events: none;
        box-shadow: 0 0 20px var(--secondary-background-color),
                    0 0 40px var(--secondary-background-color),
                    0 0 60px var(--secondary-background-color),
                    0 0 80px var(--secondary-background-color),
                    0 0 100px var(--secondary-background-color);
        animation: colors 4s infinite;
        transform: translate(-50% , -50%);
        display: none;
    }
    @keyframes colors {
        0%{
            filter: hue-rotate(0deg);
        }
        100%{
            filter: hue-rotate(360deg);
        }
    }
    .cursor::before {
        content: " ";
        position: absolute;
        background: var(--secondary-background-color) ;
        width: 30px;
        height: 30px;
        opacity: .2;
        transform: translate(-30% , -30%);
        border-radius: 50%;
    }

    #top-section {
        cursor: none !important;
    }
    /* End Cursor Style */
</style>

<script>
    import HeaderComponent from '../components/Header.vue';
    import ProjectsComponent from '../components/Projects.vue';
    import ServicesComponent from '../components/Services.vue';
    import ContactusComponent from '../components/Contactus.vue';
    import $ from 'jquery';

    export default{
        components: {
            HeaderComponent,
            ProjectsComponent,
            ServicesComponent,
            HeaderComponent,
            ContactusComponent
        },
        head: {
            script: [
                { type: 'text/javascript', src: 'https://www.googletagmanager.com/gtag/js?id=G-DMKXH1HNZB', async: true},
                { type: 'text/javascript', src: '/js/initialize.js'},
            ],
        },
        mounted: function() {
            // Cursor animation
            $('#top-section').mousemove((e) => { 
                $(".cursor").css("top", e.pageY + "px");
                $(".cursor").css("left", e.pageX + "px");
                $(".cursor").css("display", "block");
            });

            $('#top-section').mouseout(() => { 
                $(".cursor").css("display" , "none");
            });

            particlesJS.load('particles-js', 'particles.json', function() {
                // console.log('callback - particles.js config loaded');
            });
        }
    }
</script>
