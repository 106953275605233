<template>
    <section id="home" class="header-section">
        <NavbarComponent/>

        <div class="header-description">
            <div class="content text-center">
                <h1>
                    <span class="highlight-word">Misk</span> 
                    <span>Group</span>
                </h1>
                <h1>Tech</h1>

                <p class="brief">
                    We are distinguished by our expertise in the latest technological technologies in the field of the web
                </p>
            </div>
        </div>
    </section> 
</template>

<style scoped>
    .header-description{
        height: 100%;   
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
    }
    .light .header-description{
        color: rgb(68, 68, 68);
    }
    .dark .header-description{
        color: rgb(226, 226, 226);
    }
    .header-description .content{
        width: 90%;
    }
    .header-description .content h1:nth-of-type(1){
        font-size: 7rem;
    }

    @media(max-width: 900px) {
        .header-description .content h1:nth-of-type(1){
            font-size: 4rem;
        }

        .header-description .content h1:nth-of-type(2){
            font-size: 3rem;
        }
    }

   .light .header-description .content h1:nth-of-type(2){
        color: #3f3d56;
        text-shadow: 4px 5px 6px #898989;
    }
    .dark .header-description .content h1:nth-of-type(2){
        color: rgb(212, 212, 212);
        text-shadow: 4px 5px 6px #3b3b3b;
    }
    .header-section{
        height: 100vh;
    }
    .light .header-section{
        background-color: #e9e9e9;
    }
    .dark .header-section{
        background-color: #000000;
    }

    .highlight-word{
        color: #40c1f4;
        font-weight: 600;
        text-shadow: 4px 3px 3px rgb(139 139 139);
    }
    .brief {
        width: 47%;
        margin: 30px auto;
        font-size: 29px;
        font-family: 'Anek Latin', sans-serif;
    }

    @media(max-width: 900px) {
        .brief {
            width: 80%;
            font-size: 24px;
        }
    }
    .light .brief {
        color: black;
    }
    .dark .brief {
        color: rgb(217, 217, 217);
    }
</style>

<script>
    import NavbarComponent from './layouts/Navbar.vue'

    export default{
        data(){
            return{
                image : "/images/1.jpg"
            }
        },
        components : {
            NavbarComponent,
        },
    }
</script>