<template>
    <div :class="themeMode">
        <HomeView></HomeView>
    </div>
</template>

<style>
    @import '@/assets/index.css';
    @import url('https://fonts.googleapis.com/css2?family=Anek+Latin:wght@300&display=swap');

    .dark{
        background: var(--background-color);
    }
    .light{
        background: var(--light-background-color);
    }
</style>

<script setup>
    import { useSetupStore } from '../src/stores/SetupStore'
    import HomeView from './views/HomeView.vue';

    export default {
        setup() {
            const setup_store = useSetupStore()
            return { setup_store }
        },
        components: {
            HomeView
        },
        computed: {
            themeMode() {
                return this.setup_store.theme;
            }
        },
    }
</script>