<template>
    <section class="services-section py-5" id="services">
        <div class="container">
            <div class="section-name text-center mb-3">Our Services</div>

            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 my-3" v-for="service in services" :key="service.id" v-motion v-motion-pop-visible :delay="75" >
                    <div class="service-container">
                        <div class="service-icon">
                            <span class="icon rounded-circle" v-html="service.icon"></span>
                        </div>

                        <div class="service-description">
                            <div class="title">
                                <h3 class="service-title"> 
                                    {{ service.name }}
                                    <span class="service-title-animation"></span>
                                </h3>
                            </div>
                            <span class="line my-2"></span>
                            <div class="description">
                                {{ service.description }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    /*Service Container*/
    .service-container{
        border-radius: 13px;
        padding: 0 10px 0 10px ;
        text-align: center;
        transition: .4s;
        height: 100%;
        transform: scale(0.99);
    }
    .dark .service-container{
        background: #002836;
    }
    .light .service-container{
        background: var(--soft-light-background-color);
    }
    /*Service Icon*/
    .service-icon{
        left: 50%;
        top: -14px;
        transform: translate(-50%)
    }
    .service-icon .icon{
        display: inline-block;
        width: 65px;
        height: 65px;
        padding: .9rem;
    }
    .dark .service-icon .icon {
        border: solid .2rem white;
        background: var( --soft-background-color);
        color: white;
    }
    .light .service-icon .icon{
        border: solid .2rem var(--light-text-color);
        background: var(--secondary-light-background-color);
        color: var(--light-text-color);
    }
    /*End Service Icon*/
    /*End Service Container*/

    /*Service Description*/
    .service-description{
        padding-top: 5%;
        padding-bottom: 2%;
    }

   .service-description .description{
        font-size: 21px;
        font-family: 'Anek Latin', sans-serif;
    }

    .light .service-description{
        color: var(--light-text-color);
    }
    .dark .service-description{
        color: #d5d5d5;
    }

    .line{
        display: block;
        width: 60%;
        height: 1px;
        margin: auto;
        transition: .6s;
    }
    .dark .line{
        background: #84817a;
    }
    .light .line{
        background: var(--secondary-light-text-color) ;
    }
    .service-container:hover {
        transform: scale(1.05);
    }
    .service-container:hover .line{
        width: 30%;
    }
    .title .service-title {
        margin: auto;
        border-radius: 9px 0 9px 0;
        font-weight: bold;
    }

    .dark .title .service-title {
        color: white;
    }

    .service-title {
        border: none;
        display: block;
        position: relative;
        padding: 0.09rem 1.2rem;
        background: transparent;
        overflow: hidden;
        z-index: 1;
        font-weight: 500;
        width: fit-content;
    }
    .service-title .service-title-animation {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
    .service-title .service-title-animation::before {
        content: "";
        display: block;
        color: black;
        position: absolute;
        width: 8%;
        height: 400%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        transition: all 0.3s;
    }
    .service-container:hover .service-title-animation::before {
        transform: translate(-50%, -50%) ;
        width: 100%;
    }
    .dark .service-container:hover .service-title-animation::before{
        background: var(--secondary-text-color);
    }
    .light .service-container:hover .service-title-animation::before{
        background: var(--secondary-light-background-color);
    }
    .light .service-container:hover .service-title{
        color: var(--secondary-light-text-color) ;
    }
    .service-container:hover .service-title{
        color: white;
    }
    /*End Service Description*/
</style>

<script>
    export default {
        data(){
            return {
                services: [
                    {
                        id: 1 , name : "Web Application Development" ,
                        description: "Our back-end developers are experts in PHP, SQL, and more while our front-end designers are well-versed in using JavaScript, CSS3, HTML5, and more.",
                        icon: "<i class='icon fa-solid fa-layer-group'></i>",
                    },
                    {
                        id: 2 , name : "Mobile App Development",
                        description: "Using our many years of experience in mobile app development, our Mobile App Development Team will help you create smooth, seamless experiences on any mobile device.",
                        icon: "<i class='icon fa-solid fa-code'></i>"
                    },
                    {
                        id: 3 , name : "Enterprise Web Development",
                        description: "We use custom APIs and our vast knowledge of web services to build Enterprise Web Applications for digital marketing, CRM, inventory control, expedited workflows, and so much more.",
                        icon: '<i class="icon fa-solid fa-database"></i>'
                    },
                    {
                        id: 4 , name : "CMS Integration",
                        description: "We integrate CMS capabilities into your existing website or application, enabling you to add and edit web content without needing to rewrite or update any code.",
                        icon: '<i class="icon fa-solid fa-users-between-lines"></i>'
                    },
                    {
                        id: 5 , name : "Full-Stack Web Development",
                        description: "Our expert Full-Stack Web Developers are highly skilled in PHP and SQL programming languages",
                        icon: '<i class="icon fa-solid fa-file-code"></i>'
                    },
                    {
                        id: 6 , name : "Animation & Graphics Web Design",
                        description: "Our award-winning animators and graphic designers create beautiful logos, branding materials, illustrations, infographics, motion graphics, and more to fulfill your branding vision.",
                        icon: '<i class="icon fa-solid fa-user-secret"></i>'
                    },
                ]
            }
        },
    }
</script>