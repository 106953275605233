<template >
    <section class="projects-section py-5" id="projects">
        <div class="container">
            <div class="section-name text-center mb-3">
                Our projects
            </div>
            
            <div class="row">
                <div class="col-12 col-md-6 col-lg-4 my-3" v-for="project in projects" :key="project.id">
                    <div class="project-item">
                        <div class="project-container">
                            <div class="img-holder">
                                <img :src="project.image">
                            </div>
                            <div class="overlay">
                                <!-- <div class="overlay-preview">
                                    <a href="#" class="button preview-btn">
                                        <i class="fa-solid fa-link"></i>
                                        preview
                                    </a>
                                </div> -->
                                <div class="overlay-content">
                                    <div class="content container m-auto"> 
                                        {{project.description}}                                  
                                    </div>
                                </div>
                            </div>      
                        </div>
                        <div class="project-title py-3">
                            {{project.title}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
    .overlay{
        position: absolute;
        visibility: hidden;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background: #00000083;
        text-align: center;
        color: white;
    }
    .overlay-content{
        position: relative;
        height: 80%;
        overflow: hidden;
    }
    .overlay-content .content{
        position: absolute;
        overflow: hidden;
        text-overflow: ellipsis;
        bottom: -9%;
        transition: .6s;
        font-size: 1.4rem;
    }

    /* Project Container Div */
    .project-item .project-container{
        overflow: hidden;
        position: relative;
    }
    .project-item .project-container .img-holder img{
        transition: .4s;
    }
    .project-item, .project:hover {
        transition: .4s;
        border-radius: 7px;
    }
    .dark .project-item:hover {
        box-shadow: 0px 0px 26px 2px rgb(132 121 121 / 79%);
    }
    .light .project-item:hover {
        box-shadow: 0px 0px 26px 2px rgba(46, 44, 44, 0.79);
    }
    .project-item:hover img{
        transform: scale(1.1);
    }
    .project-item:hover .overlay{
        visibility: visible;
    }
    .project-item:hover .overlay-content .content{
        bottom: 25%;
    }
    .dark .img-holder{
        border: .1rem solid var(--light-background-color);
        border-bottom: none;
        overflow: hidden;
    }
    .light .img-holder{
        border: .1rem solid var(--secondary-light-text-color);
        border-bottom: none;
        overflow: hidden;
    }
    .img-holder img {
        height: 200px;
    }

    /* Project title animation */
    .project-title {
        text-align: center;
        transition: .4s;
        line-height: 2em;
        position: relative;
        overflow: hidden;
        transition: color .5s;
        z-index: 1;
        border-bottom-left-radius: 7px;
        border-bottom-right-radius: 7px;
        font-weight: 500;
        font-size: 1.4rem;
        overflow: hidden;
    }
    .dark .project-title{
        color: var(--text-color);
        background: var(--soft-background-color);
        border: .1rem solid var(--secondary-light-background-color);
        border-top: none;
    }
    .light .project-title{
        color: var(--light-text-color);
        background: var(--secondary-light-background-color);
        border: .1rem solid var(--secondary-light-text-color);
        border-top: none;
    }
    .project-title:before {
        content: "";
        position: absolute;
        z-index: -1;
        height:400px;
        width:675px;
        border-radius: 50%;
        top: 100%;
        left: 100%;
        transition: all .5s;
    }
    .dark .project-title::before {
        background: var(--background-color);
    }
    .light .project-title::before {
        background: var(--light-background-color);
    }
    .project-item:hover .project-title:before {
        top: -70%;
        left: -100%;
    }
    .project-item:hover .dark .project-title{
        color: var(--secondary-text-color);
    }
    .project-item:hover .light .project-title{
        color: var(--secondary-light-text-color);
    }

    /* Project Preview Button*/
    .overlay-preview {
        top: 5%;
        height: 20%;
    }
    .preview-btn{
        position: absolute;
        top: 10%;
        left: -20%;
        padding: 4px 6px;
        transition: 0.5s;
        border-radius: 6px;
        text-decoration: none;
        font-size: 18px;
    }
    .dark .preview-btn{
        color: var(--text-color);
        background: var(--soft-background-color);
    }
    .light .preview-btn{
        color: var(--secondary-light-text-color);
        background: var(--secondary-light-background-color);
    }
    svg:not(:host).svg-inline--fa, svg:not(:root).svg-inline--fa{
        font-size: 20px;
    }
    .preview-btn .button {
        text-decoration: none;
        padding: .3rem .2rem .3rem .2rem;
    }
    .dark .preview-btn .button{
        color: var(--text-color);
    }
    .light .preview-btn .button{
        color: var(--light-text-color);
    }
    .project-item:hover .preview-btn{
        left: 10%;
    }
</style>

<script>
    export default{
        data(){
            return {
                projects: [
                    {
                        id: 1, 
                        title: "Misk Soft", 
                        image: "/images/projects/misk-soft.png", 
                        description: "Misk Soft is an ERP System, it is include the (accounting, rentals, warehouses) modules."
                    },
                    {
                        id: 2, 
                        title: "OnScrollMedia",
                        image: "/images/projects/onscrollmedia.png", 
                        description: "Auto generator website, generate specific ad to inject it in a global advertising companies."
                    },
                    {
                        id: 3, 
                        title: "Direct", 
                        image: "/images/projects/direct.png", 
                        description: "Direct Rent A Car website for make a car reservation."
                    },
                    {
                        id: 4, 
                        title: "Arena", 
                        image: "/images/projects/arena.png", 
                        description: "Arena Rent A Car website for make a car reservation."
                    },
                    {
                        id: 5, 
                        title: "Cash Card", 
                        image: "/images/projects/cash-card.png", 
                        description: "Cash Card is an app that allows to users to purchases their needs for games or tools through two ways."
                    },
                ],
            }
        }
    }
</script>