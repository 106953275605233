<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-transparent" :class="theme">
        <div class="container-fluid">
            <RouterLink class="navbar-brand d-flex align-items-center" to="/">
                <img src="@/assets/images/misk-logo.png" alt="logo" class="d-inline-block brand-img">
            </RouterLink>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <i class="fa-solid fa-bars"></i>     
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav m-auto mb-2 mb-lg-0">
                    <li class="nav-item" v-for="(navbar_link, index) in navbar_links" :key="navbar_link.id">
                        <a class="nav-link navbar-link" :href="navbar_link.target" :class="{active : navbar_link.isActive}" @click="activateLink(index)">
                            {{ navbar_link.name }}
                        </a>
                    </li>
                </ul>
                <div class="site-controll">
                    <label class="switch">  
                        <input type="checkbox" class="checkbox-input" @click="toggleTheme">
                        <span class="slider"></span>
                    </label>
                </div>
            </div>
        </div>
    </nav>
</template>

<style scoped>
    .navbar-brand .brand-img {
        margin: 0px 20px;
        width: 140px;
    }
    .active{
        border-radius: 5px;
        transition: .4s;
        width: fit-content; 
        padding: 2px;
        text-decoration: underline;
    }
    .site-controll button:nth-child(1){
        margin-right: 8px;
        padding-right: 10px;
        border: none;
        font-size: 20px;
    }
    .site-controll button:nth-child(1):focus{
        box-shadow: none;
    }
    .dark .navbar-link {
        font-size: 1.5rem;
        width: max-content;
        color: white !important;
        margin-right: 4px;
        margin-bottom: 6px;
        border-radius: 5px;
        transition: .6s;
    }
    .light .navbar-link {
        font-size: 1.5rem;
        width: max-content;
        margin-right: 4px;
        margin-bottom: 6px;
        border-radius: 5px;
        transition: .6s;
    }
    .navbar{
        background: white;
        box-shadow: 0px 1px 7px #e9e9e9;
        position: fixed;
        width: 100%;
        z-index: 998;
        transition: .6s;
    }
    .light .navbar{
        background: white;
        box-shadow: 0px 1px 7px #e9e9e9;
    }
    .dark .navbar{
        background: rgb(0, 0, 0);
        box-shadow: 0px 0px 0px transparent;
    }

    .light .floating-navbar {
        background: white;
    }
    .dark .floating-navbar {
        background: rgb(18, 18, 18);
    }

    .dark .navbar-toggler {
        color: white ;
    }

    /* The switch - the box around the slider */
    .switch {
    /* --moon-mask: ; */
        font-size: 17px;
        position: relative;
        display: inline-block;
        width: 3.3em;
        height: 1.9em;
    }
    /* Hide default HTML checkbox */
    .switch .checkbox-input {
        opacity: 0;
        width: 0;
        height: 0;
    }
    /* The slider */
    .slider {
        position: absolute;
        cursor: none;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #8383b4;
        transition: .4s;
        border-radius: 30px;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 1.4em;
        width: 1.4em;
        border-radius: 20px;
        left: 0.3em;
        bottom: 0.3em;
        background: linear-gradient(40deg,#ff0080,#ff8c00 70%);
        transition: .4s;
    }

    .checkbox-input:checked + .slider {
        background-color: #303136;
    }
    .checkbox-input:checked + .slider:before {
        transform: translateX(1.5em);
        background: #303136;
        box-shadow: inset -3px -2px 5px -2px #8983f7, inset -10px -5px 0 0 #a3dafb;
    }
    /*End of the slider */

    .site-controll{
        display: flex;
        align-items: center;
    }
    .dropdown-menu a {
        font-size: 22px;
        font-family: 'Indie Flower', cursive;
    }
</style>>

<script>
    import { useSetupStore } from '../../stores/SetupStore'
    import $ from 'jquery';

    export default {
        setup() {
            const setup_store = useSetupStore()
            return { setup_store }
        },
        data(){
            return {
                navbar_links: [
                    {id: 1, name: "Home", path: "home", isActive: true, target:"#home"},
                    {id: 2, name: "Projects", path: "projects", isActive: false, target:"#projects"},
                    {id: 3, name: "Services", path: "services", isActive: false, target:"#services"},
                    {id: 4, name: "Contact Us", path: "contact-us", isActive: false, target:"#contact-us"},
                ],
            }
        },
        methods:{
            toggleTheme(){
                if(this.setup_store.theme == "light"){
                    this.setup_store.theme = "dark";
                }
                else{
                    this.setup_store.theme = "light";
                }

                window.pJSDom[0].pJS.fn.vendors.destroypJS();
                window.pJSDom = [];
                let optionsFile = (this.setup_store.theme == "light")? 'particles.json' : 'dark-particles.json';
                 particlesJS.load('particles-js', `${optionsFile}`, function() {
                    console.log('callback - particles.js config loaded');
                });
            },
            activateLink(index){
                this.navbar_links.forEach( navbar_link => navbar_link.isActive = false );
                this.navbar_links[index].isActive = true;
            }
        },
        computed: {
            theme() {
                return this.setup_store.theme;
            },
        },
        mounted(){
            $(window).on("scroll", () => {
                let navbarHeight = $(".navbar").height();
                if($(window).scrollTop() > navbarHeight){
                    $(".navbar").removeClass("bg-transparent")
                    $('.navbar').css('transition-duration','0.4s');
                    $(".navbar").addClass('floating-navbar');
                }
                else{
                    $(".navbar").addClass("bg-transparent")
                    $('.navbar').css('transition-duration','0.4s');
                    $(".navbar").removeClass('floating-navbar');
                }
            });

            $('.navbar-toggler').on("click", () => {
                if($(window).scrollTop() < 10) {
                    $(".navbar").toggleClass("bg-transparent")
                }
            });
        }
    }
</script>